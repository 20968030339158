import React from 'react';
import {
    ArrayField,
    Datagrid,
    DateField,
    Filter,
    List,
    SelectInput,
    Show,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import { BulkDeleteButtonDisable, CustomPagination, isGeEmployee } from './utils';
import { CardActions } from '@material-ui/core';
import CancelTransactionButton from './transactionButton/CancelTransactionButton';

const TransactionsTTPActions = ({ data, permissions }) => (
    <CardActions
        submitOnEnter={false}
        style={{ zIndex: 2, display: 'inline-block', float: 'right' }}
    >
        {isGeEmployee(permissions) && (
            <CancelTransactionButton
                record={data}
                transaction_type={'ttp'}
            />
        )}
    </CardActions>
);

const TransactionsTTPFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'id' },
                { id: 'transaction_id', name: 'Id транзакции' },
                { id: 'order_id', name: 'Id заказа' },
                { id: 'amount', name: 'Сумма транзакции' },
                { id: 'currency', name: 'Валюта транзакции' },
                { id: 'currency_code', name: 'Код валюты' },
                { id: 'account_id', name: 'Пользователь' },
                { id: 'email', name: 'Почта' },
                { id: 'description', name: 'Описание' },
                { id: 'json_data', name: 'Json' },
                { id: 'created_date_iso', name: 'Дата и время платежа' },
                { id: 'auth_date_iso', name: 'Дата и время авторизации' },
                { id: 'confirm_date_iso', name: 'Дата и время подтверждения' },
                { id: 'refunded_date_iso', name: 'Дата и время возврата' },
                { id: 'canceled_date_iso', name: 'Дата и время отмены' },
                { id: 'auth_code', name: 'Код авторизации' },
                { id: 'test_mode', name: 'Режим теста' },
                { id: 'ip_address', name: 'Ip адрес' },
                { id: 'ip_country', name: 'Ip страна' },
                { id: 'ip_city', name: 'Ip город' },
                { id: 'ip_region', name: 'Ip регион' },
                { id: 'ip_district', name: 'Ip район' },
                { id: 'ip_latitude', name: 'Ip широта' },
                { id: 'ip_longitude', name: 'Ip долгота' },
                { id: 'card_first_six', name: 'Первые шесть цифр' },
                { id: 'card_last_four', name: 'Последние четыре цифры' },
                { id: 'card_exp_date', name: 'Срок действия карты' },
                { id: 'card_type', name: 'Тип карты' },
                { id: 'card_type_code', name: 'Код типа' },
                { id: 'issuer', name: 'Эмитент' },
                { id: 'issuer_bank_country', name: 'Страна эмитента' },
                { id: 'status', name: 'Статус' },
                { id: 'status_code', name: 'Код статуса' },
                { id: 'reason', name: 'Причина' },
                { id: 'reason_code', name: 'Код причины' },
                { id: 'card_holder_message', name: 'Сообщение держателю карты' },
                { id: 'name', name: 'Имя' },
                { id: 'token', name: 'Токен' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const TransactionsTTPList = props => (
    <List
        {...props}
        filters={<TransactionsTTPFilter />}
        bulkActionButtons={<BulkDeleteButtonDisable />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="show">
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Id транзакции"
                source="transaction_id"
            />
            <TextField
                label="Id заказа"
                source="order_id"
            />
            <TextField
                label="Сумма транзакции"
                source="amount"
            />
            <TextField
                label="Оставшаяся сумма захвата"
                source="amount_capturable"
            />
            <TextField
                label="Валюта транзакции"
                source="currency"
            />
            <TextField
                label="Код валюты"
                source="currency_code"
            />
            <TextField
                label="Пользователь"
                source="account_id"
            />
            <TextField
                label="Почта"
                source="email"
            />
            <TextField
                label="Описание"
                source="description"
            />
            <TextField
                label="Json"
                source="json_data"
            />
            <DateField
                label="Дата и время платежа"
                source="created_date_iso"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время авторизации"
                source="auth_date_iso"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время подтверждения"
                source="confirm_date_iso"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время возврата"
                source="refunded_date_iso"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время отмены"
                source="canceled_date_iso"
                showTime
                locales="ru-RU"
            />
            <TextField
                label="Код авторизации"
                source="auth_code"
            />
            <TextField
                label="Режим теста"
                source="test_mode"
            />
            <TextField
                label="Ip адрес"
                source="ip_address"
            />
            <TextField
                label="Ip страна"
                source="ip_country"
            />
            <TextField
                label="Ip город"
                source="ip_city"
            />
            <TextField
                label="Ip регион"
                source="ip_region"
            />
            <TextField
                label="Ip район"
                source="ip_district"
            />
            <TextField
                label="Ip широта"
                source="ip_latitude"
            />
            <TextField
                label="Ip долгота"
                source="ip_longitude"
            />
            <TextField
                label="Первые шесть цифр"
                source="card_first_six"
            />
            <TextField
                label="Последние четыре цифры"
                source="card_last_four"
            />
            <TextField
                label="Срок действия карты"
                source="card_exp_date"
            />
            <TextField
                label="Тип карты"
                source="card_type"
            />
            <TextField
                label="Код типа"
                source="card_type_code"
            />
            <TextField
                label="Эмитент"
                source="issuer"
            />
            <TextField
                label="Страна эмитента"
                source="issuer_bank_country"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <TextField
                label="Код статуса"
                source="status_code"
            />
            <TextField
                label="Причина"
                source="reason"
            />
            <TextField
                label="Код причины"
                source="reason_code"
            />
            <TextField
                label="Сообщение держателю карты"
                source="card_holder_message"
            />
            <TextField
                label="Имя"
                source="name"
            />
            <TextField
                label="Токен"
                source="token"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const TransactionsTTPShow = ({ permissions, ...props }) => (
    <Show
        {...props}
        actions={<TransactionsTTPActions permissions={permissions} />}
    >
        <SimpleShowLayout>
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Id транзакции"
                source="transaction_id"
            />
            <TextField
                label="Id заказа"
                source="order_id"
            />
            <TextField
                label="Сумма транзакции"
                source="amount"
            />
            <TextField
                label="Оставшаяся сумма захвата"
                source="amount_capturable"
            />
            <TextField
                label="Валюта транзакции"
                source="currency"
            />
            <TextField
                label="Код валюты"
                source="currency_code"
            />
            <TextField
                label="Пользователь"
                source="account_id"
            />
            <TextField
                label="Почта"
                source="email"
            />
            <TextField
                label="Описание"
                source="description"
            />
            <TextField
                label="Json"
                source="json_data"
            />
            <DateField
                label="Дата и время платежа"
                source="created_date_iso"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время авторизации"
                source="auth_date_iso"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время подтверждения"
                source="confirm_date_iso"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время возврата"
                source="refunded_date_iso"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время отмены"
                source="canceled_date_iso"
                showTime
                locales="ru-RU"
            />
            <TextField
                label="Код авторизации"
                source="auth_code"
            />
            <TextField
                label="Режим теста"
                source="test_mode"
            />
            <TextField
                label="Ip адрес"
                source="ip_address"
            />
            <TextField
                label="Ip страна"
                source="ip_country"
            />
            <TextField
                label="Ip город"
                source="ip_city"
            />
            <TextField
                label="Ip регион"
                source="ip_region"
            />
            <TextField
                label="Ip район"
                source="ip_district"
            />
            <TextField
                label="Ip широта"
                source="ip_latitude"
            />
            <TextField
                label="Ip долгота"
                source="ip_longitude"
            />
            <TextField
                label="Первые шесть цифр"
                source="card_first_six"
            />
            <TextField
                label="Последние четыре цифры"
                source="card_last_four"
            />
            <TextField
                label="Срок действия карты"
                source="card_exp_date"
            />
            <TextField
                label="Тип карты"
                source="card_type"
            />
            <TextField
                label="Код типа"
                source="card_type_code"
            />
            <TextField
                label="Эмитент"
                source="issuer"
            />
            <TextField
                label="Страна эмитента"
                source="issuer_bank_country"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <TextField
                label="Код статуса"
                source="status_code"
            />
            <TextField
                label="Причина"
                source="reason"
            />
            <TextField
                label="Код причины"
                source="reason_code"
            />
            <TextField
                label="Сообщение держателю карты"
                source="card_holder_message"
            />
            <TextField
                label="Имя"
                source="name"
            />
            <TextField
                label="Токен"
                source="token"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
            <ArrayField
                label="История статусов"
                source="status_history"
            >
                <Datagrid>
                    <TextField
                        label="Статус"
                        source="status"
                    />
                    <TextField
                        label="Код ошибки"
                        source="code"
                    />
                    <TextField
                        label="Причина отказа"
                        source="decline_code"
                    />
                    <TextField
                        label="Время создания в TTP"
                        source="remote_created"
                    />
                    <TextField
                        label="Локальное время создания"
                        source="created"
                    />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);
